import { QueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import wretch from "wretch";

invariant(
  import.meta.env.VITE_AZURE_FUNCTIONS_URL,
  "VITE_AZURE_FUNCTIONS_URL not provided",
);

invariant(import.meta.env.VITE_SCRAPE_URL, "VITE_SCRAPE_URL not provided");
invariant(import.meta.env.VITE_EMBS_URL, "VITE_EMBS_URL not provided");

const { VITE_AZURE_FUNCTIONS_URL, VITE_SCRAPE_URL, VITE_EMBS_URL } = import.meta
  .env;

export const azureFunctionsServer = wretch(VITE_AZURE_FUNCTIONS_URL);
export const scrapeServer = wretch(VITE_SCRAPE_URL);
export const embsServer = wretch(VITE_EMBS_URL);

export const queryClient = new QueryClient();

export const queryKeys = {
  urls: (firmId: string, parentUrl: string) => [
    "urls_by_parent",
    firmId,
    parentUrl,
  ],
  ourUrls: (firmId: string) => ["our_urls", firmId],
  urlsStatuses: () => ["urls_statuses"],
  rfps: (firmId: string) => ["rfps", firmId],
  rfp: (documentId: string) => ["rfp", documentId],
  responseQuestions: (fileId: string) => ["response_questions", fileId],
  embeddingFiles: (firmId: string) => ["embeddingFiles", firmId],
  responseFile: (documentId: string) => ["responseFile", documentId],
  embedding: (embeddingId: string) => ["embedding", embeddingId],
  files: (firmId: string) => ["files", firmId],
  chatFolders: (userId: string) => ["chat_folders", userId],
  chats: (userId: string) => ["chats", userId],
  chat: (chatId: string) => ["chat", chatId],
  settings: (type: string, userId: string) => ["settings", type, userId],
  lawFirm: (userId: string) => ["lawFirm", userId],
  firmUsers: (firmId: string) => ["firmUsers", firmId],
  directorySubmissions: (firmId: string) => ["directory_submissions", firmId],
  directorySubmission: (submissionId: string) => [
    "directory_submission",
    submissionId,
  ],
  previousSubmissions: (submissionId: string) => [
    "previous_submissions",
    submissionId,
  ],
  chambersImportStatus: (fileId: string) => ["chambers_import_status", fileId],
};
